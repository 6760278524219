<!-- 新消息通知 -->
<template>
  <div class="do-page-message">
    <base-nav-bar @click-left="router.go(-1)" left-arrow title="新消息通知">
    </base-nav-bar>
    <base-bscroll :safeareaNavbar="true" :options="{ bounce: true }">
      <div class="do-page-message-content">
        <!-- @click="toPath('/my/third-party')" -->
        <div class="do-com-cellitem">
          接受新消息通知
          <div class="right">
            <van-icon name="arrow" color="rgba(197, 197, 200, 1)" />
          </div>
        </div>
        <div class="tip-text">
          如果你要关闭或开启 E客流 的新消息通知，请在 iPhone 的 “设置” - “通知”
          功能中，找到应用程序 “E客流” 更改。
        </div>
        <div class="do-com-cellitem">
          声音
          <div class="right">
            <van-switch
              v-model="voiceChecked"
              :size="$ComJs.bili * 0.35 + 'px'"
            />
          </div>
        </div>
        <div class="do-com-cellitem">
          震动
          <div class="right">
            <van-switch
              v-model="shakeChecked"
              :size="$ComJs.bili * 0.38 + 'px'"
            />
          </div>
        </div>
        <div class="tip-text">
          当 E客流 在运行时，你可以设置是否需要声音或震动。
        </div>
      </div>
    </base-bscroll>
  </div>
</template>
<script setup>
import { useRouter } from "vue-router";
import { ref } from "vue";
const router = useRouter();
const voiceChecked = ref(false);
const shakeChecked = ref(false);

console.log("router", router);
</script>

<style lang="scss">
.do-page-message {
  .do-com-navbar {
    &::after {
      border-bottom-width: 0px !important;
    }
  }
  .do-com-scroll-wrapper {
    background: $col_bg;
  }
  &-content {
    padding: 0.26rem 0.24rem;
    .do-com-cellitem {
      margin-bottom: 0.24rem;
      color: #02040f;
    }
    .tip-text {
      margin-top: 0.16rem;
      margin-bottom: 0.4rem;
      font-size: 0.24rem;
      font-weight: 400;
      color: rgba(2, 4, 15, 0.45);
    }
  }
}
</style>
